<template>
  <AutomezziDesktop v-if="!$vuetify.breakpoint.mobile" />
  <AutomezziMobile v-else></AutomezziMobile>
</template>

<script>
import AutomezziDesktop from "@/components/Automezzi/Desktop/automezzi_desktop_main.vue";
import AutomezziMobile from "@/components/Automezzi/Mobile/automezzi_mobile_main.vue"
export default {
  components: {
    AutomezziDesktop,
    AutomezziMobile
  },
};
</script>