<template>
  <!-- <div>{{automezzo.nome}}</div> -->
  <v-list-item>
    <v-list-item-avatar>
      <v-icon>mdi-truck</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ automezzo.nome }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ automezzo.targa }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Status:
        <template v-if="status">
          <span class="green--text">Online</span>
        </template>
        <template v-else>
          <span class="red--text">Offline</span>
          ({{last_update}})
        </template>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <!-- <MappaGps :id_mezzo="mezzo.id" /> -->
    </v-list-item-action>
  </v-list-item>
</template>

<script>
// import MappaGps from "./mappa_gps_mobile.vue";
import mezzo_el_mixin from "../Mixin/automezzo_element_mixin";
export default {
  components: {
    // MappaGps,
  },
  mixins: [mezzo_el_mixin],
};
</script>