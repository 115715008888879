import moment from "moment";
export default {
  props: {
    automezzo: Object,
  },
  mounted() {
    setInterval(() => {
      this.last_update = moment.unix(this.automezzo.last_update).fromNow();
    }, 1000);
  },
  data() {
    return {
      dialog: false,
      hover: false,
      last_update: "",
    };
  },
  computed: {
    status() {
      return (
        moment().diff(moment.unix(this.automezzo.last_update), "minutes") < 5
      );
    },
  },

  methods: {
    elimina() {
      this.$store.dispatch("elimina_automezzo", this.automezzo.id);
    },
  },
};
