var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-google-maps")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Tracciamento")]),_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Mostra Tracciato","inset":"","hide-details":""},model:{value:(_vm.show_trace),callback:function ($$v) {_vm.show_trace=$$v},expression:"show_trace"}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.show_trace)?_c('v-select',{attrs:{"items":_vm.periodo_list,"label":"Periodo","item-value":"value","item-text":"label","hide-details":"","dense":"","outlined":""},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}}):_vm._e()],1),(_vm.periodo == 0)?_c('v-col',{staticClass:"pl-2",attrs:{"cols":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Intervallo","outlined":"","hide-details":"","dense":"","readonly":""},model:{value:(_vm.date_format),callback:function ($$v) {_vm.date_format=$$v},expression:"date_format"}},'v-text-field',attrs,false),on))]}}],null,false,2084236369),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","allowed-dates":_vm.allowedDates},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e()],1)],1),(_vm.dialog)?_c('v-card-text',[_c('v-row',[_c('div',{staticStyle:{"width":"100%","height":"600px"}},[(_vm.show_map)?_c('GmapMap',{staticStyle:{"width":"100%","height":"600px"},attrs:{"center":_vm.center,"zoom":10}},[(false)?_vm._l((_vm.mezzo.storico),function(mark,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":mark}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:({
                        height: 10 + 'px',
                        width: 10 + 'px',
                        'background-color': _vm.colore_circle(mark.speed),
                        'border-radius': 50 + '%',
                        cursor: 'pointer',
                      })},on))]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s((mark.speed * 3.6).toFixed(1))+" km/h")])],1)],1)],1)}):_vm._e(),(_vm.show_trace)?[_c('gmap-polyline',{attrs:{"path":_vm.mezzo.storico,"options":{ strokeColor: '#008000' }},on:{"update:path":function($event){return _vm.$set(_vm.mezzo, "storico", $event)}}})]:_vm._e(),_vm._l((_vm.punti_scarico),function(mark,index){return _c('GmapCircle',{key:index,attrs:{"center":mark.coords,"radius":200,"options":{
                strokeColor: '#00FF00',
                fillColor: '#00FF00',
                strokeWeight: 1,
                fillOpacity: 0.05,
              }}})}),_vm._l((_vm.punti_scarico),function(mark,index){return _c('gmap-custom-marker',{key:index + '-scarico',attrs:{"marker":mark.coords}},[_c('v-menu',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":_vm.shop_icon(mark).color,"small":""}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.shop_icon(mark).icon))])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.cliente(mark.cliente).nome))]),_c('v-card-subtitle',[_vm._v(_vm._s(mark.destinazione))]),_c('v-card-text',[_vm._v(_vm._s(mark)+" ")])],1)],1)],1)}),_c('gmap-custom-marker',{attrs:{"marker":_vm.current_pos}},[_c('v-btn',{attrs:{"fab":"","color":"red","small":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-truck")])],1)],1)],2):_vm._e()],1)]),_c('v-row'),_c('v-row',{staticClass:"mt-4"},[_vm._v(" Velocità corrente: "+_vm._s(_vm.mezzo.speed.toFixed(0))+" Km/h ")]),_c('v-row',{staticClass:"mt-4"},[_vm._v(" Ultimo aggiornamento: "+_vm._s(_vm.ultimo_aggiornamento)+" ")]),_c('v-row')],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("chiudi")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }