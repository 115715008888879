<template>
  <v-dialog v-model="dialog" width="1200">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-google-maps</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Tracciamento</v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col cols="3">
            <v-switch
              label="Mostra Tracciato"
              v-model="show_trace"
              inset
              hide-details
              class="mt-1"
            ></v-switch>
          </v-col>
          <v-col cols="3" class="">
            <v-select
              v-if="show_trace"
              :items="periodo_list"
              v-model="periodo"
              label="Periodo"
              item-value="value"
              item-text="label"
              hide-details
              dense
              outlined
            />
          </v-col>

          <v-col cols="3" class="pl-2" v-if="periodo == 0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_format"
                  label="Intervallo"
                  outlined
                  hide-details
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                scrollable
                range
                :allowed-dates="allowedDates"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text v-if="dialog">
        <v-row>
          <div style="width: 100%; height: 600px">
            <GmapMap
              v-if="show_map"
              :center="center"
              :zoom="10"
              style="width: 100%; height: 600px"
            >
              <template v-if="false">
                <gmap-custom-marker
                  v-for="(mark, index) in mezzo.storico"
                  :key="index"
                  :marker="mark"
                >
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <div
                        :style="{
                          height: 10 + 'px',
                          width: 10 + 'px',
                          'background-color': colore_circle(mark.speed),
                          'border-radius': 50 + '%',
                          cursor: 'pointer',
                        }"
                        v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-title
                        >{{ (mark.speed * 3.6).toFixed(1) }} km/h</v-card-title
                      >
                    </v-card>
                  </v-menu>
                </gmap-custom-marker>
              </template>

              <template v-if="show_trace">
                <gmap-polyline
                  v-bind:path.sync="mezzo.storico"
                  v-bind:options="{ strokeColor: '#008000' }"
                >
                </gmap-polyline>
              </template>

              <GmapCircle
                v-for="(mark, index) in punti_scarico"
                :key="index"
                :center="mark.coords"
                :radius="200"
                :options="{
                  strokeColor: '#00FF00',
                  fillColor: '#00FF00',
                  strokeWeight: 1,
                  fillOpacity: 0.05,
                }"
              />
              <!-- <GmapMarker
            v-for="(mark, index) in path"
            :key="index"
            :position="mark"
            :shape="shape"
          /> -->
              <!-- <GmapMarker
                v-for="(mark, index) in punti_scarico"
                :key="index + '-scarico'"
                :position="mark.coords"
                :label="label_scarico"
              /> -->
              <gmap-custom-marker
                v-for="(mark, index) in punti_scarico"
                :key="index + '-scarico'"
                :marker="mark.coords"
              >
                <v-menu top>
                  <template v-slot:activator="{ on }">
                    <v-btn fab :color="shop_icon(mark).color" small v-on="on"
                      ><v-icon color="white">{{
                        shop_icon(mark).icon
                      }}</v-icon></v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>{{
                      cliente(mark.cliente).nome
                    }}</v-card-title>
                    <v-card-subtitle>{{ mark.destinazione }}</v-card-subtitle>
                    <v-card-text>{{ mark }} </v-card-text>
                  </v-card>
                </v-menu>
              </gmap-custom-marker>
              <!-- <GmapMarker
                :position="current_pos"
                :label="label"
                :draggable="true"
              /> -->
              <gmap-custom-marker :marker="current_pos">
                <v-btn fab color="red" small
                  ><v-icon color="white">mdi-truck</v-icon></v-btn
                >
              </gmap-custom-marker>
            </GmapMap>
          </div>
        </v-row>
        <v-row> </v-row>

        <v-row class="mt-4">
          Velocità corrente: {{ mezzo.speed.toFixed(0) }} Km/h
        </v-row>
        <v-row class="mt-4">
          Ultimo aggiornamento: {{ ultimo_aggiornamento }}
        </v-row>
        <v-row> </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="dialog = false">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mappa_gps_mixin from "../Mixin/mappa_gps_mixin";
import GmapCustomMarker from "vue2-gmap-custom-marker";
export default {
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  mixins: [mappa_gps_mixin],
};
</script>
