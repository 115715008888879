<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-row
        align="center"
        no-gutters
        class="py-3"
        :class="hover ? 'green lighten-5' : ''"
      >
        <v-col cols="2" class="pl-2"
          ><v-avatar size="30" class="mr-4">
            <v-icon>mdi-truck</v-icon>
          </v-avatar>
          <b>{{ automezzo.nome }}</b>
        </v-col>
        <v-col cols="1">
          {{ automezzo.marca }}
        </v-col>
        <v-col cols="2">
          {{ automezzo.targa }}
        </v-col>

        <v-col cols="2">
          <MappaGps :id_mezzo="automezzo.id" />
        </v-col>
        <v-col cols="2">
          {{last_update}}
          <v-icon class="ml-2" v-if="!automezzo.gps_status" color="red">
            mdi-map-marker-off-outline
          </v-icon>
        </v-col>
        <v-col cols="2">
          <!-- <EditFornitore :fornitore="fornitore" :show="hover" /> -->
          <v-dialog v-model="dialog" width="400">
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" v-show="hover" color="red">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="red--text"> Sei sicuro ? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" @click="dialog = false">
                  Annulla
                </v-btn>
                <v-btn outlined color="red" @click="elimina"> Elimina </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-hover>
    <v-divider />
  </div>
</template>

<script>
import moment from 'moment';
import MappaGps from "./mappa_gps.vue";
export default {
  components: {
    MappaGps,
  },
  props: {
    automezzo: Object,
  },
  mounted(){
    setInterval(() => {
      this.last_update = moment.unix(this.automezzo.last_update).fromNow()
    }, 1000);
  },
  data() {
    return {
      dialog: false,
      hover: false,
      last_update: ""
    };
  },
  
  methods: {
    elimina() {
      this.$store.dispatch("elimina_automezzo", this.automezzo.id);
    },
  },
};
</script>