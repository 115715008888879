<template>
  <v-card>
    <v-card-title class="grey--text text-subtitle-1">
      Automezzi
      <v-spacer></v-spacer>
      <v-dialog width="400">
        <template v-slot:activator="{ on }">
          <v-btn outlined color="green" v-on="on"
            ><v-icon class="mr-2">mdi-plus</v-icon>Nuovo Mezzo</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="grey--text text-subtitle-1">
            Nuovo Automezzo
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-text-field
                v-model="marca"
                prepend-icon="mdi-watermark"
                outlined
                label="Marca"
                :rules="[role]"
              ></v-text-field>
              <v-text-field
                v-model="nome"
                prepend-icon="mdi-text"
                outlined
                label="Nome"
                :rules="[role]"
              ></v-text-field>
              <v-text-field
                v-model="targa"
                prepend-icon="mdi-card-text"
                outlined
                label="Targa"
                :rules="[role]"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn outlined color="green" @click="invia()">Invia</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="2" class="pl-2"> Nome </v-col>
        <v-col cols="1"> Marca </v-col>
        <v-col cols="2"> Targa </v-col>
        <v-col cols="2"> Posizione </v-col>
        <v-col cols="2"> Ultimo Aggiornamento </v-col>
      </v-row>
      <v-divider class="mt-4" />
      <template v-for="automezzo in automezzi">
        <AutomezzoEl :automezzo="automezzo" :key="automezzo.id" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import AutomezzoEl from "./automezzo_element_desktop.vue";
import main_mixin from "../Mixin/automezzi_main"
export default {
  components: {
    AutomezzoEl,
  },
  mixins:[main_mixin]
  // data() {
  //   return {
  //     marca: "",
  //     nome: "",
  //     targa: "",
  //     valid: false,
  //     role: (val) => !!val || "Campo Richiesto",
  //   };
  // },
  // computed: {
  //   automezzi() {
  //     return this.$store.getters.get_automezzi;
  //   },
  // },
  // methods: {
  //   invia() {
  //     this.$refs.form.validate();
  //     if (!this.valid) {
  //       return;
  //     }
  //     this.$store.dispatch("nuovo_automezzo", {
  //       marca: this.marca,
  //       nome: this.nome,
  //       targa: this.targa,
  //     });
  //   },
  // },
};
</script>
