<template>
  <v-list>
    <AutomezzoEl v-for="mezzo in automezzi" :automezzo="mezzo" :key="mezzo.id" />
    <!-- {{automezzi}} -->
  </v-list>
</template>

<script>
import main_mixin from "../Mixin/automezzi_main";
import AutomezzoEl from "./automezzo_mobile_element.vue";
export default {
  components: {
    AutomezzoEl,
  },
  mixins: [main_mixin],
};
</script>