export default {
  data() {
    return {
      marca: "",
      nome: "",
      targa: "",
      valid: false,
      role: (val) => !!val || "Campo Richiesto",
    };
  },
  computed: {
    automezzi() {
      return this.$store.getters.get_automezzi;
    },
  },
  methods: {
    invia() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.$store.dispatch("nuovo_automezzo", {
        marca: this.marca,
        nome: this.nome,
        targa: this.targa,
      });
    },
  },
};
