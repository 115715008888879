import moment from "moment";
export default {
  props: {
    id_mezzo: String,
    punti_scarico: Array,
    id_consegna: String,
  },
  data() {
    return {
      dialog: false,
      show_map: false,
      show_trace: false,
      center: { lat: 44.542, lng: 7.677 },
      marker: null,
      markers: [],
      places: [],
      label: {
        text: "\ue558",
        fontFamily: "Material Icons",
        color: "#ffffff",
        fontSize: "18px",
      },
      label_scarico: {
        text: "\uea12",
        fontFamily: "Material Icons",
        color: "#ffffff",
        fontSize: "18px",
      },
      fake_path: [],
      periodo_list: [
        {
          label: "Oggi",
          value: {
            ts_inizio: moment().startOf("day").format("X"),
            ts_fine: moment().endOf("day").format("X"),
          },
        },
        {
          label: "Ieri",
          value: {
            ts_inizio: moment().subtract(1, "day").startOf("day").format("X"),
            ts_fine: moment().subtract(1, "day").endOf("day").format("X"),
          },
        },
        { label: "Da... a...", value: 0 },
      ],
      periodo: {
        ts_inizio: moment().startOf("day").format("X"),
        ts_fine: moment().endOf("day").format("X"),
      },
    };
  },
  watch: {
    dialog: function () {
      if (this.dialog) {
        console.log("Caricamento dati mappa");
        let ts_inizio = moment().subtract(240, "minutes").format("X");
        let ts_fine = moment().format("X");
        this.$store
          .dispatch("fetch_storico_gps_consegna", {
            id_mezzo: this.id_mezzo,
            ts_inizio: ts_inizio,
            ts_fine: ts_fine,
            id_consegna: this.id_consegna,
          })
          .then(() => {
            this.show_map = true;
          });
      } else {
        this.show_map = false;
      }
    },
    show_trace: function(){
      this.aggiorna_dati()
    },
    periodo: function () {
      this.aggiorna_dati();
    },
  },
  computed: {
    mezzo() {
      return this.$store.getters.get_mezzo_by_id(this.id_mezzo);
    },
    current_pos() {
      return { lat: this.mezzo.latitudine, lng: this.mezzo.longitudine };
    },
    percorso() {
      return this.mezzo;
    },
    ultimo_aggiornamento() {
      return moment.unix(this.mezzo.last_update).fromNow();
    },
  },
  methods: {
    colore_circle(raw_speed) {
      let speed = raw_speed * 3.6;
      if (speed < 10) {
        return "#B3E5FC";
      } else if (speed < 20) {
        return "#29B6F6";
      } else if (speed < 30) {
        return "#0288D1";
      } else if (speed < 40) {
        return "#01579B";
      } else if (speed < 50) {
        return "#43A047";
      } else if (speed < 70) {
        return "#00E676";
      } else if (speed < 90) {
        return "#F57C00";
      } else if (speed < 110) {
        return "#E65100";
      } else {
        return "#BF360C";
      }
    },
    cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
    aggiorna_dati() {
      this.show_map = false;
      this.$store
        .dispatch("fetch_storico_gps", {
          id_mezzo: this.id_mezzo,
          ts_inizio: this.periodo.ts_inizio,
          ts_fine: this.periodo.ts_fine,
        })
        .then(() => {
          this.show_map = true;
        });
    },
    shop_icon(mark) {
      if (mark.raggiunta && !mark.completa) {
        return {
          icon: "mdi-human-dolly",
          color: "purple",
        };
      } else if (mark.completa) {
        return {
          icon: "mdi-check",
          color: "green",
        };
      } else {
        return {
          icon: "mdi-storefront",
          color: "blue",
        };
      }
    },
  },
};
